import { render, staticRenderFns } from "./ResizeAnchor.vue?vue&type=template&id=db357042&scoped=true"
import script from "./ResizeAnchor.vue?vue&type=script&lang=js"
export * from "./ResizeAnchor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db357042",
  null
  
)

export default component.exports